(function (window, document, undefined) {

    'use strict';
    var myLazyLoad = new LazyLoad();

    var nav = document.getElementById('nav');

    window.onscroll = function() {
        if ( window.pageYOffset > 100 ) {
            nav.classList.add("nav--is-sticky");
        } else {
            nav.classList.remove("nav--is-sticky");
        }
    }

})(window, document);
